import { Controller } from '@hotwired/stimulus';
import { destroy, patch } from '@rails/request.js';

export default class CartController extends Controller {
  static targets = ['item', 'coupon', 'form'];

  connect() {
    super.connect();
  }

  async addItem(evt) {
    evt.preventDefault();
    const {id} = evt.params;

    const res = await fetch('/api/v2/storefront/cart/add_item', {
      method: 'post',
      credentials: 'same-origin',
      mode: 'same-origin',
      headers: {
        'Content-Type': 'application/vnd.api+json',
        'X-Spree-Order-Token': SpreeAPI.orderToken,
      },
      body: JSON.stringify({
        variant_id: id,
        quantity: 1,
      }),
    });
    if (res.ok) {
      this.dispatch('notify', {prefix: 'notifications', detail: {type: 'success', message: 'Product added to cart'}, target: evt.target});
      this.dispatch('updated', {detail: {type: 'success', variant: id}});
      document.dispatchEvent(new Event('cart:updated'));
    } else {
      await res.json().then(error => {
        this.dispatch('notify', {prefix: 'notifications', detail: {type: 'error', message: error.error}, target: evt.target});
      });
    }
  }

  async removeItem(evt) {
    evt.preventDefault();
    const id = evt.params.id;

    const res = await fetch('/api/v2/storefront/cart/remove_line_item/' + id,
      {
        method: 'DELETE',
        credentials: 'same-origin',
        mode: 'same-origin',
        headers: {
          'X-Spree-Order-Token': SpreeAPI.orderToken,
        },
      },
    );

    if (res.ok) {
      document.dispatchEvent(new Event('cart:updated'));
      this.dispatch('updated', {detail: {type: 'success', variant: id}});
      if (this.hasItemTarget) {
        const line_item = this.itemTargets.filter(i => i.contains(evt.target)).shift();
        line_item.remove();
        Turbo.visit(Spree.localizedPathFor('cart/summary'), {frame: 'cart-summary'});
      }
    }
  }

  async removeCoupon(evt) {
    evt.preventDefault();
    const {coupon} = evt.params;
    const res = await destroy(Spree.routes.api_v2_storefront_cart_remove_coupon_code(coupon), {
      contentType: 'application/json',
      responseKind: 'json',
      headers: {
        'X-Spree-Order-Token': SpreeAPI.orderToken,
      },
    });
    if(res.ok) {

    }
    console.log(res);
  }

  async applyCoupon(evt) {
    evt.preventDefault();
    const res  = await patch('/cart/apply_coupon', {
      contentType: 'application/json',
      responseKind: 'turbo',
      body: {
        coupon_code: this.couponTarget.value,
      }
    });
    console.log(res);
  }
}
